import React, { useCallback, useEffect, useState } from "react";
import { Form, Button, Row, Col, Drawer, Spin, Upload, message } from "antd";
import { vsmConfirmBooking, vsmNotify } from "../../../../config/messages";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPrint, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../../../component/InputComponent";
import moment from "moment";
import debounce from "lodash/debounce";
import { UploadIcon } from "../../../../config/IconsConfig";
import Text from "antd/lib/typography/Text";
import { CurrencyFormat, getFilterArrayByID, newPaymentStatus, getRollbackStatus, depositedStatus, receiptStatus, modelType, successfulStatus, RefundStatus, booking_payment_type } from "../../../../utils/GlobalFunction";

const ConfirmComponent = observer((props) => {

	const [form] = Form.useForm();

	const { viewData = null, isCancelled = false } = props;

	const {
		ManageZFormsStore,
		DisbursementStore,
		PaymentDepositedStore,
		PaymentFailedStore,
		PaymentReceivedStore,
		PaymentSuccessfulStore,
		RefundStore,
		PaymentStore,
		AUTH
	} = useStore();

	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [fetchPaymentMode, setFetchPaymentMode] = useState(true);
	const [fetchBanks, setFetchBanks] = useState(true);
	const [fetchOwnedBanks, setFetchOwnedBanks] = useState(true);
	const [fetchPaymentStatus, setFetchPaymentStatus] = useState(true);
	const [fetchPaymentReason, setFetchPaymentReason] = useState(true);
	const [paymentMode, setPaymentMode] = useState(null)
	const [is_disabled, setIsDisabled] = useState(false)
	const [isReasonVisible, setisReasonVisible] = useState(false)
	const [isRecoRequired, setisRecoRequired] = useState(false)
	const [isDepoACRequired, setisDepoACRequired] = useState(false)
	const [receiptDateDisable, setReceiptDateDisable] = useState(false)
	const dateFormat = "DD/MM/YYYY";
	const [fileList, updateFileList] = useState([]);
	const [isImageUploaded, setisImageUploaded] = useState(false)
	const [type, setType] = useState(null);
	const [enableSearch, setenableSearch] = useState(false);
	const apiFlags = ManageZFormsStore.api_check_flag

	//---------------------------------------------------------------------------------------------------//
	//-------------------------------------- Form Submit Function ---------------------------------------//

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true);
		const formData = new FormData();
		let booking_id;
		if (form.getFieldValue("zform_search_id") !== null && form.getFieldValue("zform_search_id") !== undefined) {
			booking_id = form.getFieldValue("zform_search_id")
		}
		else if (viewData !== null) {
			booking_id = viewData.booking_id;
		}
		else if (ManageZFormsStore.viewValues) {
			booking_id = ManageZFormsStore.viewValues.id;
		}
		else {
			booking_id = ManageZFormsStore.confirmValues.id;
		}
		formData.append("booking_id", booking_id)
		if ([1, 2, 3].includes(props.type.model) && ["edit"].includes(props.type.type)) {
			formData.append("payment_id", ManageZFormsStore?.confirmValues?.id)
		}
		formData.append("date", moment(data.date).format("YYYY-MM-DD"))
		formData.append("mop_id", data.mop_id)
		formData.append("amount", data.amount)
		data.bank_id && formData.append("bank_id", data.bank_id)
		data.cheque_no && formData.append("cheque_no", data.cheque_no)
		data.bank_acc_id && formData.append("bank_acc_id", data.bank_acc_id)
		data.reco_date && formData.append("reco_date", moment(data.reco_date).format("YYYY-MM-DD"))
		if (modelType[props.type.model] === 'Refund') {
			formData.append("reco_date", moment(new Date()).format("YYYY-MM-DD"))
		}
		data.depo_date && formData.append("depo_date", moment(data.depo_date).format("YYYY-MM-DD"))
		formData.append("status_id", data.status_id)
		data.reason_id && formData.append("reason_id", data.reason_id)
		data.remarks && formData.append("remarks", data.remarks)

		if ([1, 2, 3].includes(props.type.model)) {
			formData.append("type", apiFlags[props.type.model].type)
		}
		if (isImageUploaded) {
			formData.append("ref_image", fileList[0]?.originFileObj ? fileList[0]?.originFileObj : fileList[0]);
		}
		ManageZFormsStore.commonSave(apiFlags[props.type.model][props.type.type].api + (booking_id), formData, props.type.model === 0 ? true : false)
			.then((data) => {
				if ([1, 2, 3].includes(props.type.model) && ManageZFormsStore.viewValues) {
					ManageZFormsStore.viewValues.booking_payments = data.booking_payments
					ManageZFormsStore.viewValues.booking_ledger.balance = data.booking_ledger.balance
					ManageZFormsStore.viewValues.booking_ledger.total_credits = data.booking_ledger.total_credits
					ManageZFormsStore.viewValues.booking_ledger.total_refund = data.booking_ledger.total_refund
					ManageZFormsStore.viewValues.booking_ledger.excess_disc = data.booking_ledger.excess_disc
					ManageZFormsStore.fetchPaymentImages()
				}
				if (props.type?.storeType) {
					if (props.type.storeType === "Disbursement" && DisbursementStore.agGrid) {
						DisbursementStore.setupGrid(DisbursementStore.agGrid);
					}
					if (props.type.storeType === "Payment Deposited" && PaymentDepositedStore.agGrid) {
						PaymentDepositedStore.setupGrid(PaymentDepositedStore.agGrid);
					}
					if (props.type.storeType === "Payment Failed" && PaymentFailedStore.agGrid) {
						PaymentFailedStore.setupGrid(PaymentFailedStore.agGrid);
					}
					if (props.type.storeType === "Payment Received" && PaymentReceivedStore.agGrid) {
						PaymentReceivedStore.setupGrid(PaymentReceivedStore.agGrid);
					}
					if (props.type.storeType === "Payment Successful" && PaymentSuccessfulStore.agGrid) {
						PaymentSuccessfulStore.setupGrid(PaymentSuccessfulStore.agGrid);
					}
					if (props.type.storeType === "Refund" && RefundStore.agGrid) {
						RefundStore.setupGrid(RefundStore.agGrid);
					}
					if (props.type.storeType === "Payments All" && PaymentStore.agGrid) {
						PaymentStore.setupGrid(PaymentStore.agGrid);
					}
				}
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	//---------------------------------------------------------------------------------------------------//

	//-------------------------------------- useEffect API Call -----------------------------------------//
	useEffect(() => {
		// setIsDisabled(false)
		if (props.type && props.visible && ManageZFormsStore.confirmValues) {
			setType(props.type.model);
			if (props.type.type === "add") {
				form.setFieldsValue({
					date: moment()
				})
			}
			if (["view", "edit"].includes(props.type.type)) {
				setReceiptDateDisable(true)
			}

			let params = null
			if ([1, 2, 3].includes(props.type.model) && ["view", "edit"].includes(props.type.type)) {
				params = { id: viewData !== null ? viewData.booking_id : ManageZFormsStore.viewValues.id, payment_id: ManageZFormsStore.confirmValues.id }
			}
			const booking_id = props.type.model === 0 ? ManageZFormsStore.confirmValues.id : viewData !== null ? viewData.booking_id : ManageZFormsStore.viewValues.id
			ManageZFormsStore.getCommonPayment(apiFlags[props.type.model]["view"].api + booking_id, params)
			setIsDisabled(apiFlags[props.type.model][props.type.type].disabled)
		}
	}, [form, props, ManageZFormsStore, ManageZFormsStore.confirmValues, apiFlags, viewData])

	//---------------------------------------------------------------------------------------------------//

	//-------------------------------------- Status Change Event ----------------------------------------//

	const handleStatusChange = useCallback(() => {
		setisDepoACRequired(false)
		setisRecoRequired(false);
		setisReasonVisible(false);
		const status_id = form.getFieldValue("status_id");
		if ([2, 3, 4].includes(status_id)) {
			setisDepoACRequired(true);
		}
		if (status_id === 5) {
			setisReasonVisible(true);
		}
		else if (status_id === 4) {
			setisRecoRequired(true);
		}
		else {
			setisReasonVisible(false);
			setisRecoRequired(false);
		}
		if (props.type.model === 1) {
			if ([1, 2, 3, 5].includes(status_id)) {
				form.setFieldsValue({ reco_date: null })
			}
			if ([1].includes(status_id)) {
				form.setFieldsValue({ bank_acc_id: null })
			}
		}
	}, [form, props])

	//---------------------------------------------------------------------------------------------------//

	//---------------------------------- useEffect Search Enable ----------------------------------------//

	useEffect(() => {
		if (props.visible && ManageZFormsStore.confirmValues === null) {
			setType(props.type.model);
			setenableSearch(true);
		}
	}, [props, ManageZFormsStore.confirmValues])

	//---------------------------------------------------------------------------------------------------//

	//---------------------------------- useEffect Form Setter ------------------------------------------//

	useEffect(() => {
		if (props.visible && ManageZFormsStore.get_confirmation_payment) {
			form.setFieldsValue({
				zform: ManageZFormsStore.get_confirmation_payment.id,
				co_no: ManageZFormsStore.get_confirmation_payment?.co_no ? ManageZFormsStore.get_confirmation_payment.co_no : null,
				customer_name: ManageZFormsStore.get_confirmation_payment.booking_customer.title.name + " " + (ManageZFormsStore.get_confirmation_payment.booking_customer.changed_name ? ManageZFormsStore.get_confirmation_payment.booking_customer.changed_name : ManageZFormsStore.get_confirmation_payment.booking_customer.full_name),
				sales_consultant: ManageZFormsStore.get_confirmation_payment.sales_consultant.name,
				model: ManageZFormsStore.get_confirmation_payment.booking_model.model.name,
				variant: ManageZFormsStore.get_confirmation_payment.booking_model.variant.name,
				color: ManageZFormsStore.get_confirmation_payment.booking_model.color.name,
			})
			if (ManageZFormsStore.get_confirmation_payment.booking_payments.length > 0) {
				let ref_image_id = ManageZFormsStore.get_confirmation_payment.payment?.doc_id ? ManageZFormsStore.get_confirmation_payment.payment?.doc_id : ManageZFormsStore.get_confirmation_payment.payment?.ref_image_id
				if (ref_image_id) {
					PaymentStore.getImageUrl(ref_image_id).then(res => {
						ManageZFormsStore.confirmValues.ref_image = res;
						ManageZFormsStore.confirmValues.ref_image && updateFileList([
							{
								uid: "-1",
								name: "image.png",
								status: "done",
								url: ManageZFormsStore.confirmValues.ref_image,
							},
						]);
					})
				}

				ManageZFormsStore.dropdown_payment_mode_list = ManageZFormsStore.get_confirmation_payment.payment?.mop_id ? [ManageZFormsStore.get_confirmation_payment.payment?.payment_mode] : null;
				ManageZFormsStore.dropdown_bank_list = ManageZFormsStore.get_confirmation_payment.payment?.bank_id ? [ManageZFormsStore.get_confirmation_payment.payment?.bank] : null;
				ManageZFormsStore.dropdown_deposited_bankac = ManageZFormsStore.get_confirmation_payment?.booking_payments[0]?.bank_account ? [{
					id: ManageZFormsStore.get_confirmation_payment?.booking_payments[0]?.bank_account?.id,
					deposited_bank: ManageZFormsStore.get_confirmation_payment?.booking_payments[0]?.bank_account?.deposited_bank
				}] : null;
				ManageZFormsStore.dropdown_payment_status_list = ManageZFormsStore.get_confirmation_payment.payment?.payment_status ? [ManageZFormsStore.get_confirmation_payment.payment?.payment_status] : null;
				form.setFieldsValue({
					receipt_no: ManageZFormsStore.get_confirmation_payment.payment?.receipt_no ? ManageZFormsStore.get_confirmation_payment.payment?.receipt_no : null,
					date: ManageZFormsStore.get_confirmation_payment.payment?.date ? moment(ManageZFormsStore.get_confirmation_payment.payment?.date) : null,
					amount: ManageZFormsStore.get_confirmation_payment.payment?.amount ? ManageZFormsStore.get_confirmation_payment.payment?.amount : null,
					cheque_no: ManageZFormsStore.get_confirmation_payment.payment?.cheque_no ? ManageZFormsStore.get_confirmation_payment.payment?.cheque_no : null,
					depo_date: ManageZFormsStore.get_confirmation_payment.payment?.depo_date ? moment(ManageZFormsStore.get_confirmation_payment.payment?.depo_date) : null,
					reco_date: ManageZFormsStore.get_confirmation_payment.payment?.reco_date ? moment(ManageZFormsStore.get_confirmation_payment.payment?.reco_date) : null,
					remarks: ManageZFormsStore.get_confirmation_payment.payment?.remarks ? ManageZFormsStore.get_confirmation_payment.payment?.remarks : null,
					bank_id: ManageZFormsStore.get_confirmation_payment.payment?.bank_id,
					mop_id: ManageZFormsStore.get_confirmation_payment.payment?.mop_id,
					reason_id: ManageZFormsStore.get_confirmation_payment.payment?.reason_id,
					bank_acc_id: ManageZFormsStore.get_confirmation_payment.payment?.bank_acc_id,

				})
				if (modelType[props.type.model] === 'Refund') {
					if (props.type.type === 'add' && ManageZFormsStore.get_confirmation_payment.booking_ledger?.balance < 0) {
						form.setFieldsValue({
							amount: Math.abs(ManageZFormsStore.get_confirmation_payment.booking_ledger?.balance) ? Math.abs(ManageZFormsStore.get_confirmation_payment.booking_ledger?.balance) : null,
						})
					}
				}
				if (is_disabled) {
					form.setFieldsValue({
						status_id: ManageZFormsStore.get_confirmation_payment.payment?.status_id
					})
				}
			}
			else {
				form.setFieldsValue({
					receipt_no: ManageZFormsStore.get_confirmation_payment.receipt_no ? ManageZFormsStore.get_confirmation_payment.receipt_no + " (subject to change)" : null
				})
			}
			if (props.type.type === "add") {
				form.setFieldsValue({
					date: moment()
				})
			}
			handleStatusChange()
		}
	}, [form, props, is_disabled, ManageZFormsStore, ManageZFormsStore.get_confirmation_payment, handleStatusChange, PaymentStore])

	//---------------------------------------------------------------------------------------------------//

	//---------------------------------- Search/Handle Functions ----------------------------------------//

	const searchZform = () => {
		if (props.type && props.visible) {
			setType(props.type.model);
			if (props.type.type === "add") {
				form.setFieldsValue({
					date: moment()
				})
			}
			if (["view", "edit"].includes(props.type.type)) {
				setReceiptDateDisable(true)
			}

			let params = null
			if ([1, 2, 3].includes(props.type.model) && ["view", "edit"].includes(props.type.type)) {
				params = {
					id: form.getFieldValue("zform_search_id"),
					// , payment_id: ManageZFormsStore.confirmValues.id
				}
			}
			params = {
				type_id: apiFlags[props?.type?.model]["type"],
				...params
			}
			// params.type = apiFlags[props?.type?.model]["type"];
			const booking_id = form.getFieldValue("zform_search_id")
			ManageZFormsStore.getCommonPayment(apiFlags[props.type.model]["view"].api + booking_id, params)
			setIsDisabled(apiFlags[props.type.model][props.type.type].disabled)
		}

	}
	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((data) => {
				setDisabled(false);
			})
			.catch((e) => {
				setDisabled(true);
			});
	}, 500);

	const handlePaymentModeChange = () => {
		const paymentMode = form.getFieldValue("mop_id")
		const paymentModeObj = ManageZFormsStore.dropdown_payment_mode_list.filter((item) => item.id === paymentMode)
		if (paymentMode) {
			setPaymentMode(paymentModeObj)
		} else {
			setPaymentMode(null)
		}
		handleChange()
	}

	const disabledDate = (current) => {
		return current && current.format('YYYY-MM-DD') !== moment().startOf("day").format('YYYY-MM-DD');
	};

	const disabledRecoDate = (current) => {
		if (form.getFieldValue('status_id') === 4 && [0, 1, 3].includes(props?.type?.model)) {
			return current && ((current > moment().endOf("day")) || (current.isBefore(form.getFieldValue("depo_date"))));
		}
		else {
			return current && ((current > moment().endOf("day")) || (current.isBefore(form.getFieldValue("date"))));
		}
	};

	const disabledDepoDate = (current) => {
		return current && ((current > moment().endOf("day")) || (current.isBefore(form.getFieldValue("date"))));
	};

	//---------------------------------------------------------------------------------------------------//

	//--------------------------------- Image Upload Functions -----------------------------------------//
	// Handle on remove image
	const onRemoveImage = () => {
		form.setFields([{ name: "ref_image", errors: [] }]);
		updateFileList([]);
		setisImageUploaded(false)
		setDisabled(true);
	}

	const eventProps = {
		onChange: ({ fileList: newFileList }) => {
			updateFileList(newFileList);
		},
		fileList,
		beforeUpload: (file) => {
			let isJpg =
				file.type === "image/png" ||
				file.type === "image/jpeg" || file.type === "image/jpg";
			if (!isJpg) {
				message.error(`Valid image formats are JPEG and PNG.`);
				setisImageUploaded(false)
				setDisabled(true);
				return true;
			} else {
				setDisabled(false);
				updateFileList([file])
				setisImageUploaded(true)
				return false
			}
		},
	};

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};

	const uploadButton = (
		<div className="upload_btn">
			<UploadIcon />
			<p>Drag files to upload, or browse</p>
		</div>
	);

	//---------------------------------------------------------------------------------------------------//

	//------------------------------------ Get Status List Function -------------------------------------//

	const getStatusByType = () => {

		let status_list = ManageZFormsStore.dropdown_payment_status_list || [];
		if (props.type) {
			if (modelType[props.type.model] === 'Refund') {

				if (props.type.type === 'add') {
					status_list = status_list.filter(x => successfulStatus.includes(x.id));
				}
				else if (props.type.type === 'edit') {
					status_list = status_list.filter(x => RefundStatus.includes(x.id));
				}
			}
			else {
				if (props.type.type === 'add') {
					status_list = status_list.filter(x => newPaymentStatus.includes(x.id));
				}
				else if (props.type.type === 'edit') {
					if (props?.viewData?.status_id) {
						status_list = status_list.filter(x => getFilterArrayByID(props?.viewData?.status_id).includes(x.id));
					}
				}
			}
		}
		return status_list;
	}

	/*
		Update on 8th sep for task-1328
	*/
	const generatePaymentDocument = (id, booking_id) => {
		let formData = {
			id: booking_id,
			payment_id: id
		}
		PaymentStore.generatePaymentDocumentData(formData).then((data) => {
			window.open(data.url);
			ManageZFormsStore.setViewValues({
				id: ManageZFormsStore.viewValues.id,
			});
			vsmNotify.success({
				message: data.STATUS.NOTIFICATION[0],
			});
		})
	}
	
	//---------------------------------------------------------------------------------------------------//

	//--------------------------------------- Close  Function   -----------------------------------------//

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
		setDisabled(true);
		setFetchPaymentMode(true);
		setFetchBanks(true);
		setFetchOwnedBanks(true);
		setFetchPaymentStatus(true);
		setFetchPaymentReason(true);
		setPaymentMode(null)
		setisReasonVisible(false);
		setisRecoRequired(false);
		setisDepoACRequired(false)
		updateFileList([]);
		setisImageUploaded(false)
		setReceiptDateDisable(false)
		setType(null);
		setenableSearch(false);
		ManageZFormsStore.confirmValues = null
		ManageZFormsStore.get_confirmation_payment = null
		ManageZFormsStore.dropdown_payment_mode_list = null
		ManageZFormsStore.dropdown_bank_list = null
		ManageZFormsStore.dropdown_deposited_bankac = null
		ManageZFormsStore.dropdown_payment_status_list = null
	};

	//---------------------------------------------------------------------------------------------------//

	const rollBackStatus = () => {
		let remarks = form.getFieldValue("remarks");
		if (remarks) {
			let booking_id;
			let data = form.getFieldsValue();
			const formData = new FormData();
			if (form.getFieldValue("zform_search_id") !== null && form.getFieldValue("zform_search_id") !== undefined) {
				booking_id = form.getFieldValue("zform_search_id")
			}
			else if (viewData !== null) {
				booking_id = viewData.booking_id;
			}
			else if (ManageZFormsStore.viewValues) {
				booking_id = ManageZFormsStore.viewValues.id;
			}
			else {
				booking_id = ManageZFormsStore.confirmValues.id;
			}
			let rollback_status = getRollbackStatus(form.getFieldValue('status_id'))
			if ([1, 2, 3].includes(props.type.model)) {
				formData.append("payment_id", ManageZFormsStore?.confirmValues?.id)
			}
			formData.append("remarks", data.remarks)
			if (rollback_status === null) {
				let payload = {
					id: booking_id,
					payment_id: ManageZFormsStore?.confirmValues?.id,
				}
				ManageZFormsStore.savePaymentCancelled(payload).then((data) => {
					if ([1, 2, 3].includes(props.type.model) && ManageZFormsStore.viewValues) {
						ManageZFormsStore.viewValues.booking_payments = data.booking_payments
						ManageZFormsStore.viewValues.booking_ledger.balance = data.booking_ledger.balance
						ManageZFormsStore.viewValues.booking_ledger.total_credits = data.booking_ledger.total_credits
						ManageZFormsStore.viewValues.booking_ledger.total_refund = data.booking_ledger.total_refund
						ManageZFormsStore.viewValues.booking_ledger.excess_disc = data.booking_ledger.excess_disc
						ManageZFormsStore.fetchPaymentImages()
					}
					if (props.type?.storeType) {
						if (props.type.storeType === "Disbursement" && DisbursementStore.agGrid) {
							DisbursementStore.setupGrid(DisbursementStore.agGrid);
						}
						if (props.type.storeType === "Payment Deposited" && PaymentDepositedStore.agGrid) {
							PaymentDepositedStore.setupGrid(PaymentDepositedStore.agGrid);
						}
						if (props.type.storeType === "Payment Failed" && PaymentFailedStore.agGrid) {
							PaymentFailedStore.setupGrid(PaymentFailedStore.agGrid);
						}
						if (props.type.storeType === "Payment Received" && PaymentReceivedStore.agGrid) {
							PaymentReceivedStore.setupGrid(PaymentReceivedStore.agGrid);
						}
						if (props.type.storeType === "Payment Successful" && PaymentSuccessfulStore.agGrid) {
							PaymentSuccessfulStore.setupGrid(PaymentSuccessfulStore.agGrid);
						}
						if (props.type.storeType === "Refund" && RefundStore.agGrid) {
							RefundStore.setupGrid(RefundStore.agGrid);
						}
						if (props.type.storeType === "Payments All" && PaymentStore.agGrid) {
							PaymentStore.setupGrid(PaymentStore.agGrid);
						}
					}
					close();
					vsmNotify.success({
						message: data.STATUS.NOTIFICATION[0],
					});
				})
					.catch((e) => {
						if (e.errors) {
							form.setFields(e.errors);
						}
					})
					.finally(() => { });
			}
			else {

				formData.append("date", moment(data.date).format("YYYY-MM-DD"))
				formData.append("mop_id", data.mop_id)
				formData.append("amount", data.amount)
				data.bank_id && formData.append("bank_id", data.bank_id)
				data.cheque_no && formData.append("cheque_no", data.cheque_no)
				data.reason_id && formData.append("reason_id", data.reason_id)

				if ([1, 2, 3].includes(props.type.model)) {
					formData.append("type", apiFlags[props.type.model].type)
				}
				if (isImageUploaded) {
					formData.append("ref_image", fileList[0]?.originFileObj ? fileList[0]?.originFileObj : fileList[0]);
				}
				formData.append("booking_id", booking_id)
				if (rollback_status === 2) {
					// formData.append("reco_date", null);
				}
				else {
					data.reco_date && formData.append("reco_date", moment(data.reco_date).format("YYYY-MM-DD"))
				}

				if (rollback_status === 1) {
					// formData.append("bank_acc_id", null)
				}
				else {
					data.bank_acc_id && formData.append("bank_acc_id", data.bank_acc_id)
					data.depo_date && formData.append("depo_date", moment(data.depo_date).format("YYYY-MM-DD"))
				}
				formData.append("status_id", rollback_status)
				ManageZFormsStore.commonSave(apiFlags[props.type.model]["edit"].api + (booking_id), formData, false).then((data) => {
					if ([1, 2, 3].includes(props.type.model) && ManageZFormsStore.viewValues) {
						ManageZFormsStore.viewValues.booking_payments = data.booking_payments
						ManageZFormsStore.viewValues.booking_ledger.balance = data.booking_ledger.balance
						ManageZFormsStore.viewValues.booking_ledger.total_credits = data.booking_ledger.total_credits
						ManageZFormsStore.viewValues.booking_ledger.total_refund = data.booking_ledger.total_refund
						ManageZFormsStore.viewValues.booking_ledger.excess_disc = data.booking_ledger.excess_disc
						ManageZFormsStore.fetchPaymentImages()
					}
					if (props.type?.storeType) {
						if (props.type.storeType === "Disbursement" && DisbursementStore.agGrid) {
							DisbursementStore.setupGrid(DisbursementStore.agGrid);
						}
						if (props.type.storeType === "Payment Deposited" && PaymentDepositedStore.agGrid) {
							PaymentDepositedStore.setupGrid(PaymentDepositedStore.agGrid);
						}
						if (props.type.storeType === "Payment Failed" && PaymentFailedStore.agGrid) {
							PaymentFailedStore.setupGrid(PaymentFailedStore.agGrid);
						}
						if (props.type.storeType === "Payment Received" && PaymentReceivedStore.agGrid) {
							PaymentReceivedStore.setupGrid(PaymentReceivedStore.agGrid);
						}
						if (props.type.storeType === "Payment Successful" && PaymentSuccessfulStore.agGrid) {
							PaymentSuccessfulStore.setupGrid(PaymentSuccessfulStore.agGrid);
						}
						if (props.type.storeType === "Refund" && RefundStore.agGrid) {
							RefundStore.setupGrid(RefundStore.agGrid);
						}
						if (props.type.storeType === "Payments All" && PaymentStore.agGrid) {
							PaymentStore.setupGrid(PaymentStore.agGrid);
						}
					}
					close();
					vsmNotify.success({
						message: data.STATUS.NOTIFICATION[0],
					});
				})
					.catch((e) => {
						if (e.errors) {
							form.setFields(e.errors);
						}
					})
					.finally(() => { });
			}
		}
		else {
			vsmNotify.error({
				message: "Remarks can not be blank",
			});
		}
	}

	const getRollbackText = () => {
		if (viewData.status_id) {
			if (depositedStatus.includes(viewData.status_id)) {
				return <p>Rollback to Bank Reco.?</p>
			}
			if (receiptStatus.includes(viewData.status_id)) {
				return <p>Rollback to Receipt?</p>
			}
			if (newPaymentStatus.includes(viewData.status_id)) {
				return <p>Cancel Receipt?</p>
			}
		}
	}

	const handleViewImage = (id) => {
		ManageZFormsStore.getImageUrl(id).then((data) => {
			if (data) {
				onPreviewNew(data)
			}
		}).catch((e) => {

		})
	}

	const onPreviewNew = async (src) => {
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};


	return props.visible && props.type ? (
		<Drawer
			className="addModal"
			title={apiFlags[props.type.model].title}
			width="70%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={close}
			footer={[
				<>
					{
						!is_disabled ?
							(<>
								<Button
									key="2"
									htmlType="button"
									className="cancelBtn mr-35"
									onClick={close}
								>
									Cancel
								</Button>
								<Button
									key="1"
									disabled={disabled}
									form="confirmForm"
									loading={saving}
									htmlType="submit"
									type="primary"
								>
									Save
								</Button>
							</>
							)
							: (
								!isCancelled && props?.type?.isRollback ?
									<>
										{getRollbackText()}
										<Button
											key="2"
											htmlType="button"
											className="cancelBtn mr-35"
											onClick={close}
										>
											No
										</Button>
										<Button
											key="1"
											htmlType="button"
											type="primary"
											onClick={() => rollBackStatus()}
										>
											Yes
										</Button>
									</>
									:
									null
							)
					}
				</>
			]}
		>
			<Form
				form={form}
				id="confirmForm"
				onFinish={handleSubmit}
				labelCol={{ span: 24 }}
				onChange={handleChange}
			>
				<Row gutter={30}>
					{enableSearch ?
						<>
							<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }} className="zformSearchBtnWithInput">
								<InputComponent
									type="text"
									label="ZForm"
									placeholder="ZForm"
									name="zform_search_id"
								/>
								<div className="alignBottom zformSearchBtn">
									<Button
										type="text"
										title={"Search"}
										className="viewIcon mr-10"
										size="large"
										style={{ padding: 7 }}
										onClick={() => {
											searchZform();
										}}
									>
										<FontAwesomeIcon icon={faSearch} />
									</Button>
								</div>
							</Col>
						</>
						:
						<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
							<InputComponent
								type="text"
								disabled={true}
								label="ZForm"
								placeholder="ZForm"
								name="zform"
							/>
						</Col>
					}
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
						<InputComponent
							type="text"
							disabled={true}
							label="CO NO"
							placeholder="CO NO"
							name="co_no"
						/>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} xl={{ span: 12 }}>
						<InputComponent
							type="text"
							disabled={true}
							label="Customer"
							placeholder="Customer"
							name="customer_name"
						/>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
						<InputComponent
							type="text"
							disabled={true}
							label="Sales Consultant"
							placeholder="Sales Consultant"
							name="sales_consultant"
						/>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
						<InputComponent
							type="text"
							disabled={true}
							label="Model"
							placeholder="Model"
							name="model"
						/>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
						<InputComponent
							type="text"
							disabled={true}
							label="Variant"
							placeholder="Variant"
							name="variant"
						/>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
						<InputComponent
							type="text"
							disabled={true}
							label="Color"
							placeholder="Color"
							name="color"
						/>
					</Col>
				</Row>

				<Row gutter={30}>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
						<h3>{apiFlags[props.type.model].title} Information</h3>
						{props.type.model === 1 || props.type.model === 0 ? <Text type="danger">Maximum cash limit for an order is Rs {CurrencyFormat({ value: AUTH.company.preferences.cash_limit })}</Text> : ""}
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
						<InputComponent
							type="text"
							disabled={true}
							label="Receipt No"
							placeholder="Receipt No"
							name="receipt_no"
						/>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
						<InputComponent
							type="date"
							mode="date"
							format={dateFormat}
							required
							disabled={receiptDateDisable}
							disabledDate={disabledDate}
							label="Payment"
							placeholder="Receipt Date"
							name="date"
						/>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
						<InputComponent
							type="select"
							allowClear
							required
							disabled={is_disabled}
							autoComplete="chrome-off"
							label="Payment Mode"
							name="mop_id"
							placeholder="Select Payment Mode"
							rules={vsmConfirmBooking.validation.payment_mode}
							onChange={() => { handleChange(); handlePaymentModeChange(); }}
							onFocus={() =>
								fetchPaymentMode &&
								ManageZFormsStore.getPaymentMethods(apiFlags[props.type.model].type).then(() => setFetchPaymentMode(false))
							}
							notFoundContent={
								fetchPaymentMode ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageZFormsStore.dropdown_payment_mode_list,
								value_key: "id",
								text_key: "name",
								rejected_keys:
									ManageZFormsStore.dropdown_payment_mode_list && !fetchPaymentMode &&
									ManageZFormsStore.dropdown_payment_mode_list
										.filter((item) => item.status === 0)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
						<InputComponent
							type="text"
							required
							label="Amount"
							placeholder="Amount"
							name="amount"
							disabled={is_disabled}
							rules={vsmConfirmBooking.validation.amount}
						/>
					</Col>
					{type === 2 ?
						<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
							<InputComponent
								type="select"
								allowClear
								required
								disabled={is_disabled}
								autoComplete="chrome-off"
								label="Bank A/C"
								name="bank_acc_id"
								placeholder="Select Bank A/C"
								rules={vsmConfirmBooking.validation.deposited_bank}
								onChange={handleChange}
								onFocus={() =>
									fetchOwnedBanks &&
									ManageZFormsStore.getDepositedBankAC(ManageZFormsStore?.get_confirmation_payment?.location_id).then(() => setFetchOwnedBanks(false))
								}
								notFoundContent={
									fetchOwnedBanks ? <Spin size="small" /> : "No Record Found."
								}
								options={{
									values: ManageZFormsStore.dropdown_deposited_bankac,
									value_key: "id",
									text_key: 'deposited_bank',
								}}
							/>
						</Col>
						:
						<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
							<InputComponent
								type="select"
								allowClear
								disabled={is_disabled}
								required={paymentMode && paymentMode[0].bank_flag === 1 ? true : false}
								autoComplete="chrome-off"
								label="Bank Name"
								name="bank_id"
								placeholder="Select Bank Name"
								rules={paymentMode && paymentMode[0].bank_flag === 1 ? vsmConfirmBooking.validation.bank_id : [{ required: false, message: "" }]}
								onChange={handleChange}
								onFocus={() =>
									fetchBanks &&
									ManageZFormsStore.getBanks().then(() => setFetchBanks(false))
								}
								notFoundContent={
									fetchBanks ? <Spin size="small" /> : "No Record Found."
								}
								options={{
									values: ManageZFormsStore.dropdown_bank_list,
									value_key: "id",
									text_key: "name",
									rejected_keys:
										ManageZFormsStore.dropdown_bank_list && !fetchBanks &&
										ManageZFormsStore.dropdown_bank_list
											.filter((item) => item.status === 0)
											.map((item) => item.id),
								}}
							/>
						</Col>
					}
					<Col className="tooltipText" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
						<InputComponent
							type="text"
							required={paymentMode && paymentMode[0].cheque_flag === 1 ? true : false}
							label="Doc. No"
							placeholder="Doc. No"
							name="cheque_no"
							tooltip="It is either Cheque No or DD No or Bank Note No. or Any reference Transaction No Remove deposited bank"
							disabled={is_disabled}
							rules={paymentMode && paymentMode[0].cheque_flag === 1 ? vsmConfirmBooking.validation.chequet_no : [{ required: false, message: "" }]}
						/>
					</Col>
				</Row>
				<Row gutter={30}>

					{type !== 2 && !['add'].includes(props?.type?.type) ?
						<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
							<InputComponent
								type="select"
								allowClear
								required={[0, 1, 3].includes(type) && isDepoACRequired}
								disabled={is_disabled || type === 2 || [2].includes(props?.viewData?.status_id)}
								autoComplete="chrome-off"
								label="Deposited Bank A/C"
								name="bank_acc_id"
								placeholder="Select Deposited Bank A/C"
								rules={[1, 3].includes(type) && isDepoACRequired ? vsmConfirmBooking.validation.deposited_bank : [{ required: false, message: "" }]}
								onChange={handleChange}
								onFocus={() =>
									fetchOwnedBanks &&
									ManageZFormsStore.getDepositedBankAC(ManageZFormsStore?.get_confirmation_payment?.location_id).then(() => setFetchOwnedBanks(false))
								}
								notFoundContent={
									fetchOwnedBanks ? <Spin size="small" /> : "No Record Found."
								}
								options={{
									values: ManageZFormsStore.dropdown_deposited_bankac,
									value_key: "id",
									text_key: 'deposited_bank',
								}}
							/>
						</Col>
						: null
					}
					{
						[0, 1, 3].includes(props?.type?.model) && !['add'].includes(props?.type?.type) ?
							<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
								<InputComponent
									type="date"
									mode="date"
									required={[2, 3, 4].includes(form.getFieldValue('status_id'))}
									rules={[2, 3, 4].includes(form.getFieldValue('status_id')) ? vsmConfirmBooking.validation.depo_date : vsmConfirmBooking.validation.depo_date_noVal}
									format={dateFormat}
									onChange={handleChange}
									disabledDate={disabledDepoDate}
									label="Date Deposited"
									placeholder="Date Deposited"
									name="depo_date"
									disabled={is_disabled || [2].includes(props?.viewData?.status_id)}
								/>
							</Col>
							: null
					}
					{!['add'].includes(props?.type?.type) && ![1].includes(props?.viewData?.status_id) ?
						<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
							<InputComponent
								type="date"
								mode="date"
								required={isRecoRequired}
								rules={form.getFieldValue('status_id') === 4 ? vsmConfirmBooking.validation.reco_date : vsmConfirmBooking.validation.reco_date_noVal}
								format={dateFormat}
								onChange={handleChange}
								disabledDate={disabledRecoDate}
								label="Reco. Date"
								placeholder="Reco. Date"
								name="reco_date"
								disabled={is_disabled}
							/>
						</Col>
						:
						null
					}
					<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
						<InputComponent
							type="select"
							allowClear
							autoComplete="chrome-off"
							label="Status"
							name="status_id"
							required
							disabled={is_disabled}
							placeholder="Select Status"
							rules={vsmConfirmBooking.validation.status}
							onChange={() => {
								handleStatusChange();
								handleChange();
							}}
							onFocus={() =>
								fetchPaymentStatus &&
								ManageZFormsStore.getStatus({ parent_id: null }).then(() => setFetchPaymentStatus(false))
							}
							notFoundContent={
								fetchPaymentStatus ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: getStatusByType(),
								value_key: "id",
								text_key: "name",
								rejected_keys:
									ManageZFormsStore.dropdown_payment_status_list && !fetchPaymentStatus &&
									ManageZFormsStore.dropdown_payment_status_list
										.filter((item) => item.status === 0)
										.map((item) => item.id),
							}}
						/>
					</Col>
					{isReasonVisible ?
						<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
							<InputComponent
								type="select"
								allowClear
								autoComplete="chrome-off"
								label="Reason"
								name="reason_id"
								required={isReasonVisible}
								disabled={is_disabled}
								placeholder="Select Reason"
								rules={isReasonVisible ? vsmConfirmBooking.validation.reason : [{ required: false, message: "" }]}
								onChange={handleChange}
								onFocus={() =>
									fetchPaymentReason &&
									ManageZFormsStore.getReason({ parent_id: form.getFieldValue("status_id") }).then(() => setFetchPaymentReason(false))
								}
								notFoundContent={
									fetchPaymentReason ? <Spin size="small" /> : "No Record Found."
								}
								options={{
									values: ManageZFormsStore.dropdown_reason_list,
									value_key: "id",
									text_key: "name",
									rejected_keys:
										ManageZFormsStore.dropdown_reason_list && !fetchPaymentReason &&
										ManageZFormsStore.dropdown_reason_list
											.filter((item) => item.status === 0)
											.map((item) => item.id),
								}}
							/>
						</Col>
						: null
					}
					<Col xs={{ span: 24 }}>
						<InputComponent
							type="textarea"
							label="Remarks"
							required={props?.type?.isRollback}
							placeholder="Remarks"
							name="remarks"
							disabled={isCancelled || (props?.type?.type === "view" && !props?.type?.isRollback)}
							rules={vsmConfirmBooking.validation.remarks}
						/>
					</Col>
				</Row>
				{/*****  Payment Start *****/}
				<div className="drawerTable ledgerView">
					<div className="tableHeader paymentBtn">
						<h3>Payments</h3>

					</div>
					<div className="tableSec">
						<div className="accessoryTableSec">
							<table className="accessoryTablenew">
								<thead>
									<tr>
										<th>Type</th>
										<th>Status</th>
										<th>Receipt No</th>
										<th>Date</th>
										<th>Amount</th>
										<th>Payment Mode</th>
										<th>Bank</th>
										<th>Cheque No</th>
										<th>Deposited Bank</th>
										<th>Reco. Date</th>
										<th>Image</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{
										ManageZFormsStore.get_confirmation_payment?.booking_payments.length > 0 ?
											ManageZFormsStore.get_confirmation_payment?.booking_payments.map((item) => (
												<tr key={item.id}>
													<td>{booking_payment_type[item.type]}</td>
													<td>{item.payment_status ? item.payment_status?.name : "N/A"}</td>
													<td>{item.receipt_no}</td>
													<td>{moment(item.date).format("DD/MM/YYYY")}</td>
													<td>{CurrencyFormat({ value: item.amount })}</td>
													<td>{item.payment_mode.name}</td>
													<td>{item.bank?.name ? item.bank?.name : "N/A"}</td>
													<td>{item.cheque_no ? item.cheque_no : "N/A"}</td>
													<td>{item.bank_account ? item.bank_account.deposited_bank : "N/A"}</td>
													<td>{item.reco_date ? moment(item.reco_date).format("DD/MM/YYYY") : "N/A"}</td>
													<td>{item.ref_image_id ? <Button type="text"
														title={"View Image"}
														className="viewIcon"
														onClick={() => {
															handleViewImage(item.ref_image_id);
														}}><FontAwesomeIcon icon={faEye} /></Button> : "N/A"}</td>
													<td>
														{console.log(item)}
														{(AUTH.checkPrivileges("#8013#") && (item.type === 20 ? true : [1, 2, 3, 4].includes(item.status_id))) ? <Button type="text"
															title={"Download"}
															className="resignIcon "
															size="large"
															onClick={() => {
																generatePaymentDocument(item.id, item.booking_id);
															}}><FontAwesomeIcon icon={faPrint} /></Button> : "N/A"}
													</td>
												</tr>
											))
											:
											<tr>
												<td colSpan="11">There is no payments.</td>
											</tr>
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				{/*****  Payment End *****/}
				<div className="upload_verify_sec">
					<Row justify="space-between" align="middle" gutter={30}>
						<Col xs={{ span: 24 }} sm={{ span: 14 }}>
							<div className="upload_left_sec">
								<p>
									Upload Reference Cloud
								</p>
								<ul>
									<li>1. This is Optional</li>
									<li>2. It could be screenshot</li>
									<li>3. It must be an image with extension JPG, JPEG, JPEG and PNG</li>
								</ul>
							</div>
						</Col>
						<Col xs={{ span: 24 }} sm={{ span: 8 }}>
							<Form.Item name="ref_image">
								<Upload
									accept=".png,.jpeg,.jpg,.gif"
									showUploadList={true}
									multiple={false}
									fileList={fileList}
									onRemove={onRemoveImage}
									onPreview={onPreview}
									{...eventProps}
									listType="picture-card"
									disabled={is_disabled}
								>
									{fileList.length >= 1 ? null : uploadButton}
								</Upload>
							</Form.Item>
						</Col>
					</Row>
				</div>
			</Form >
		</Drawer >
	) : null;
});

export default ConfirmComponent;
