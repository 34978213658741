import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Row, Col, Spin } from "antd";
import { vsmMunicipalTax, vsmNotify } from "../../../../config/messages";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../../../component/InputComponent";
import debounce from "lodash/debounce";

const AddComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageMunicipalTaxStore,
		ManageMunicipalTaxStore: {
			AddData,
			getMunicipalityList,
			copyData,
			setCopyData
		},
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [fetchMunicipality, setFetchMunicipality] = useState(true);

	useEffect(() => {
		if (copyData !== null) {
			form.setFieldsValue({
				min_amt: copyData.min_amt,
				max_amt: copyData.max_amt,
				fixed_charge: copyData.fixed_charge,
				tax_per: copyData.tax_per,
			});
		}
	},[copyData, form])

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true);
		AddData(data)
			.then((data) => {
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);

	// reset form and close add form
	const close = () => {
		props.close();
		setCopyData(null)
		setFetchMunicipality(true)
		ManageMunicipalTaxStore.dropdown_municipality_list = null;
		form.resetFields();
		setDisabled(true);
	};

	return (
		<Modal
			className="addModal"
			centered
			title="New Municipal Tax"
			width={534}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
				<Button
					key="1"
					disabled={disabled}
					form="addMunicipalTaxForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>,
			]}
		>
			<Form
				form={form}
				id="addMunicipalTaxForm"
				onFinish={handleSubmit}
				labelCol={{ span: 24 }}
				onChange={handleChange}
			>
				<Row gutter={30}>
					<Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <InputComponent
							type="select"
							allowClear
							required
							autoComplete="chrome-off"
							label="Municipality Name"
							name="municipality_id"
							placeholder="Select Municipality"
							rules={vsmMunicipalTax.validation.municipality_id}
							onChange={handleChange}
							onFocus={() =>
								fetchMunicipality && getMunicipalityList().then(() => setFetchMunicipality(false))
							}
							notFoundContent={
								fetchMunicipality ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageMunicipalTaxStore.dropdown_municipality_list,
								value_key: "id",
								text_key: "name",
								rejected_keys:
									ManageMunicipalTaxStore.dropdown_municipality_list &&
									ManageMunicipalTaxStore.dropdown_municipality_list
										.filter((item) => item.status === 0)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }}>
						<InputComponent
							type="tel"
							required
							label="Minimun Amount"
							placeholder="Minimun Amount"
							name="min_amt"
							rules={vsmMunicipalTax.validation.min_amt}
						/>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }}>
						<InputComponent
							type="tel"
							required
							label="Maximun Amount"
							placeholder="Maximun Amount"
							name="max_amt"
							rules={vsmMunicipalTax.validation.max_amt}
						/>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }}>
						<InputComponent
							type="tel"
							required
							label="Fixed Charge"
							placeholder="Fixed Charge"
							name="fixed_charge"
							rules={vsmMunicipalTax.validation.fixed_charge}
						/>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 12 }}>
						<InputComponent
							type="text"
							required
							label="Tax %"
							placeholder="Tax %"
							name="tax_per"
							rules={vsmMunicipalTax.validation.tax_per}
						/>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
});

export default AddComponent;
