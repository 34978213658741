import React, { useState } from "react";
import { Form, Button, Modal, Col, Row } from "antd";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { vsmNotify } from "../../../../../../config/messages";
import useStore from "../../../../../../store";

const DeleteMunicipalComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageZFormsStore
	} = useStore();

	const [saving, setSaving] = useState();
	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true);
        data.type_id = 30; // type_id 30 for delete municipal_id
        data.booking_id = ManageZFormsStore.viewValues?.id;
        data.municipal_id = ManageZFormsStore.viewValues?.booking_ledger?.municipal_id;
		ManageZFormsStore.applyMunicipal(data)
			.then((data) => {
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
				close();
			})
			.catch((e) => {
				if (e.errors) {
					vsmNotify.error({
                        message: data.STATUS.NOTIFICATION[0],
                    });
				}
			})
			.finally(() => {
				setSaving(false);
			});
	};

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
	};

	return ManageZFormsStore.viewValues?.booking_ledger?.municipal_id !== null ? (
		<Modal
			centered
			className="deleteModal"
			title="Delete Municipal tax"
			zIndex={1005}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
				<Button
					key="1"
					form="deleteMunicipalForm"
					loading={saving}
					htmlType="submit"
					type="primary"
					danger
				>
					Delete
				</Button>,
			]}
		>
			<Form form={form} id="deleteMunicipalForm" onFinish={handleSubmit}>
				<Row align="middle">
					<Col span={24} className="text-center">
						<h3>
							Would you like to remove municipal tax from this z-form?
						</h3>
					</Col>
				</Row>
			</Form>
		</Modal>
	) : null;
});

export default DeleteMunicipalComponent;
