import { PageHeader, Select } from "antd";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import { useState } from "react";
import PendingListComponent from "./component/PendingListComponent";
import PendingByLocationListComponent from "./component/PendingByLocationListComponent"
import HistoryListComponent from "./component/HistoryListComponent";
import HistoryListByLocationComponent from "./component/HistoryByLocationListComponent";
import ViewComponent from "./component/ViewComponent";
import ApproveComponent from "./component/ApproveComponent";
import RejectComponent from "./component/RejectComponent";
import LedgerComponent from "../ManageZForms/component/LedgerComponent";
import { default_roles } from "../../../utils/GlobalFunction";

const { Option } = Select;

const SchemeDiscountRequest = observer((props) => {
	const [viewModal, setViewModal] = useState(false);
	const [approveModal, setApproveModal] = useState(false);
	const [rejectModal, setRejectModal] = useState(false);
	const [viewLedgerModal, setViewLedgerModal] = useState(false);
	const [viewType, setViewType] = useState("Pending");
	const [remarks, setRemarks] = useState();
	const {
		SchemeDiscReqPendingStore: {
			setPageSize,
			setViewValues,
			per_page
		},
		ManageZFormsStore,
		SchemeDiscReqHistoryStore,
		AUTH
	} = useStore();

	const handleChange = (value) => {
		setViewType(value)
	};

	// Open & Close  form for Reset ledger
	const openViewModal = (data) => {
		setViewValues(data)
		setViewModal(true)
	};
	const closeViewModal = () => {
		setViewModal(false);
		if (SchemeDiscReqHistoryStore.agGrid) {
			SchemeDiscReqHistoryStore.setupGrid(SchemeDiscReqHistoryStore.agGrid);
		}
	}

	// Open & Close  form for Reset ledger
	const openApproveModal = (data) => {
		setApproveModal(true)
		setRemarks(data.remarks)
	};
	const closeApproveModal = () => setApproveModal(false);

	// Open & Close  form for Reset ledger
	const openRejectModal = (data) => {
		setRejectModal(true);
		setRemarks(data.remarks)
	}
	const closeRejectModal = () => setRejectModal(false);

	// Open & Close  form for edit State
	const openViewLedgerModal = (data) => {
		ManageZFormsStore.setViewValues({ id: data.request.id })
		setViewLedgerModal(true);
	};
	const closeViewLedgerModal = () => setViewLedgerModal(false)

	return (
		<PageHeader
			title={BreadcrumbConfig.SchemeDiscountRequest.title + (viewType  === 'Pending' ? " - My Pending List" : viewType === 'History' ? " - My History List" : viewType  === 'PendingByLocation' ? " - All Pending List" : " - All History List")}
			className="tableAreaSec"
			extra={
				<BreadcrumbComponent items={BreadcrumbConfig.SchemeDiscountRequest.path} />
			}
		>
			<div className="listCountNew">
				{/* <Button key="1"
					onClick={handleChange}
				>
					{viewType ? "History" : "Pending"}
				</Button> */}
					{AUTH?.user?.role_id === default_roles.mis_executive || AUTH?.user?.role_id === default_roles.admin || AUTH?.user?.role_id === default_roles.vp
					?
						(<Select value={viewType} onChange={handleChange}>
							<Option key={0} value="Pending">
								My Pending List
							</Option>
							<Option key={1} value="History">
								My History List
							</Option>
							<Option key={2} value="PendingByLocation">
								All Pending List
							</Option>
							<Option key={3} value="HistoryByLocation">
								All History List
							</Option> 
						</Select>) : (
						<Select value={viewType} onChange={handleChange}>
							<Option key={0} value="Pending">
								My Pending List
							</Option>
							<Option key={1} value="History">
								My History List
							</Option> 
						</Select>
						)
					}
				<RecordPerPage
					key="2"
					style={{ width: "150px" }}
					defaultValue={per_page + " per page"}
					onChange={setPageSize}
				/>
			</div>

			<ApproveComponent remarks={remarks} visible={approveModal} close={closeApproveModal}
				parentModalClose={closeViewModal} />
			<RejectComponent remarks={remarks} visible={rejectModal} close={closeRejectModal}
				parentModalClose={closeViewModal} />

			<LedgerComponent visible={viewLedgerModal} close={closeViewLedgerModal} />

			<ViewComponent
				visible={viewModal}
				close={closeViewModal}
				openApproveModal={openApproveModal}
				openRejectModal={openRejectModal}
				showZformBtn={true}
				openViewLedgerModal={openViewLedgerModal}
			/>

			{/* {viewType && (viewType === 'Pending') ?
				<PendingListComponent
					openViewModal={openViewModal}
				/>
				:
				<HistoryListComponent
					openViewModal={openViewModal}
				/>
			} */}
			{viewType && viewType === "Pending" ? (
				<PendingListComponent
					openViewModal={openViewModal}
				/>
			) : viewType && viewType === "History" ? (
				<HistoryListComponent
					openViewModal={openViewModal}
				/>
			) : viewType && viewType === "PendingByLocation" && (AUTH?.user?.role_id === default_roles.mis_executive || AUTH?.user?.role_id === default_roles.admin || AUTH?.user?.role_id === default_roles.vp) ? (
				<PendingByLocationListComponent
					openViewModal={openViewModal}
				/>
			) : viewType && viewType === "HistoryByLocation" && (AUTH?.user?.role_id === default_roles.mis_executive || AUTH?.user?.role_id === default_roles.admin || AUTH?.user?.role_id === default_roles.vp) ? (
				<HistoryListByLocationComponent
					openViewModal={openViewModal}
				/>
			) : ''}
		</PageHeader>
	);
});

export default SchemeDiscountRequest;
