import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import ServerGridConfig from "../../../config/ServerGridConfig";
import { globalStatus } from "../../../utils/GlobalFunction";

export default class SchemeDiscReqHistoryStore {
	AUTH = null
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	history_list_data = null;
	history_by_location_list_data = null;
	total = 0;
	allColumnIds = [];
	show_by_location = 0;


	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		const { api } = params
		var datasource = this.createDatasource(ServerGridConfig.options)
		api.setServerSideDatasource(datasource)

	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel
		var final_sort = params.sortModel
		if (final_filter.level) {
			let values_changed = []
			final_filter.level.values.forEach(x => (
				values_changed.push(globalStatus('scheme_offer_level', 'value', x))
			))
			final_filter.level.values = values_changed
		}
		if (final_filter["status"]) {
			let values_changed = []
			final_filter["status"].values.forEach(x => (
				values_changed.push(globalStatus('scheme_request_status', 'value', x))
			))
			final_filter["status"].values = values_changed
		}
		return { final_filter, final_sort }
	}

	setShowByLocation = (data) => {
		this.show_by_location = data;
	}

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request)
				let showByLocation = this.show_by_location;
				var payload = {
					show_by_location: showByLocation,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				
				if(showByLocation === 1) {
					this.getHistoryByLocationList(payload).then((data) => {
							if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
							else { this.agGrid.api.hideOverlay() }
							params.successCallback(data.list.data, data.list.total)
							var allColumnIds = []
							if (this.agGrid && this.agGrid.columnApi && data.total) {
								this.agGrid.columnApi.getAllColumns().forEach(function (column) {
									allColumnIds.push(column.colId)
								})
							}
						})
				} else {
					this.getHistoryList(payload).then((data) => {
						if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
						else { this.agGrid.api.hideOverlay() }
						params.successCallback(data.list.data, data.list.total)
						var allColumnIds = []
						if (this.agGrid && this.agGrid.columnApi && data.total) {
							this.agGrid.columnApi.getAllColumns().forEach(function (column) {
								allColumnIds.push(column.colId)
							})
						}
					})
				}
			}
		}
	}

	// call api to get history records
	getHistoryList = (payload) => {
		this.history_list_data = null;
		return Axios.post(`/sales/scheme_offer/history_list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.created = item.created ? moment(item.created).format("DD/MM/YYYY") : "N/A";
					item.last_action_date = item.last_action_date ? moment(item.last_action_date).format("DD/MM/YYYY") : "N/A";
					if (item.booking?.booking_customer?.full_name) {
						item.booking.booking_customer.full_name = item.booking.booking_customer.changed_name ? item.booking.booking_customer.changed_name : item.booking.booking_customer.full_name
					}
					return null;
				});
			}
			this.history_list_data =
				data.list && data.list.data.length ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data
		});
	};

	// call api to get history by location records
	getHistoryByLocationList = (payload) => {
		this.history_by_location_list_data = null;
		return Axios.post(`/sales/scheme_offer/history_list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.created = item.created ? moment(item.created).format("DD/MM/YYYY") : "N/A";
					item.last_action_date = item.last_action_date ? moment(item.last_action_date).format("DD/MM/YYYY") : "N/A";
					if (item.booking?.booking_customer?.full_name) {
						item.booking.booking_customer.full_name = item.booking.booking_customer.changed_name ? item.booking.booking_customer.changed_name : item.booking.booking_customer.full_name
					}
					return null;
				});
			}
			this.history_by_location_list_data =
				data.list && data.list.data.length ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data
		});
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

}

decorate(SchemeDiscReqHistoryStore, {
	per_page: observable,
	agGrid: observable,
	history_list_data: observable,
	history_by_location_list_data: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getHistoryList: action,
	onFilterChanged: action,
	type: observable,
	defaultType: observable,
	show_by_location: observable,
	setShowByLocation: action,
});
