import { PageHeader } from "antd";
import { observer } from "mobx-react";
import ListComponent from "./component/ListComponent";
import BreadcrumbComponent from "../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import DRFComponent from "./component/ApplyDRFComponent";
import { useState } from "react";

// import { vsmNotify } from "../../../config/messages";

const ManageDRF = observer((props) => {
	const [drfModal, setDrfModal] = useState(false)
	const {
		ManageDRFStore: {
			setPageSize,
			per_page,
			setEditValues,
		},
	} = useStore();

	// Open & Close  form for DRF
	const openDRFModal = (data) => {
		setEditValues(data);
		setDrfModal(true);
	};
	const closeDRFModal = () => setDrfModal(false);



	return (
		<PageHeader
			title={BreadcrumbConfig.ManageDRF.title}
			className="tableAreaSec"
			extra={
				<BreadcrumbComponent items={BreadcrumbConfig.ManageDRF.path} />
			}
		>
			<div className="listCountNew">
				<RecordPerPage
					key="2"
					style={{ width: "150px" }}
					defaultValue={per_page + " per page"}
					onChange={setPageSize}
				/>
			</div>
			{/* DRF */}
			<DRFComponent
				visible={drfModal}
				close={closeDRFModal}
			/>
			<ListComponent openDRFModal={openDRFModal}/>
		</PageHeader>
	);
});

export default ManageDRF;
